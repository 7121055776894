import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { BoldText } from '../../components/bold-text/bold-text';
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Listen zählen zu den grundlegenden Bausteinen des Webs. Jede:r Web-Entwickler:in kennt die
HTML-Elemente `}<InlineCode mdxType="InlineCode">{`<`}{`ul`}{`>`}</InlineCode>{` und `}<InlineCode mdxType="InlineCode">{`<`}{`ol`}{`>`}</InlineCode>{` für
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/de/docs/Web/HTML/Element/ul"
      }}>{`ungeordnete`}</a>{`
und `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/de/docs/Web/HTML/Element/ol"
      }}>{`geordnete Listen`}</a>{`.
Doch wenige kennen ihren nahen Verwandten `}<InlineCode mdxType="InlineCode">{`<`}{`dl`}{`>`}</InlineCode>{`, die Beschreibungsliste.`}</p>
    <p>{`Ein typischer Anwendungsfall ist die Implementierung eines Glossars mit Begriffen und Definitionen.
Tatsächlich kann man aber noch viel mehr damit machen. Jede Liste von Schlüssel-Wert-Paaren kann als
Beschreibungsliste gestaltet werden. Es verbessert die DX, also
`}<a parentName="p" {...{
        "href": "https://medium.com/swlh/what-is-dx-developer-experience-401a0e44a9d9"
      }}>{`Entwickler-Erfahrung`}</a>{`, indem es den Code besser
lesbar macht. Und es verbessert die Barrierefreiheit eurer Web-Inhalte.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "63.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAIB/9oADAMBAAIQAxAAAAFjtM2jmkP/xAAbEAACAwADAAAAAAAAAAAAAAABEgACExEhIv/aAAgBAQABBQLVpfhRiYO6q8t5P//EABURAQEAAAAAAAAAAAAAAAAAAAAh/9oACAEDAQE/AUf/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAdEAACAQQDAAAAAAAAAAAAAAAAATECEBGBEkFx/9oACAEBAAY/AtC47IwNx4ijq3//xAAZEAEBAQEBAQAAAAAAAAAAAAABETEAIVH/2gAIAQEAAT8hsZcdokRdb0NafK8W4IUw6I4dnVAfO//aAAwDAQACAAMAAAAQ4B//xAAXEQADAQAAAAAAAAAAAAAAAAAAARFR/9oACAEDAQE/EHGtJg//xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAECAQE/EAxu3//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESExQYFxocH/2gAIAQEAAT8QM2MgG7W4bYjBoAc+p3fAfZrroASh13Lt9iqN5y+cQ2fyB4n/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Zwei Personen besprechen den HTML-Code auf dem Laptop-Monitor vor ihnen.",
          "title": "Zwei Personen besprechen den HTML-Code auf dem Laptop-Monitor vor ihnen.",
          "src": "/static/d4e56c8e213c409c18497393ef2b4a01/e5166/pexels-ketut-subiyanto-confused-couple.jpg",
          "srcSet": ["/static/d4e56c8e213c409c18497393ef2b4a01/f93b5/pexels-ketut-subiyanto-confused-couple.jpg 300w", "/static/d4e56c8e213c409c18497393ef2b4a01/b4294/pexels-ketut-subiyanto-confused-couple.jpg 600w", "/static/d4e56c8e213c409c18497393ef2b4a01/e5166/pexels-ketut-subiyanto-confused-couple.jpg 1200w", "/static/d4e56c8e213c409c18497393ef2b4a01/b17f8/pexels-ketut-subiyanto-confused-couple.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Ketut Subiyanto / pexels.com`}</em></p>
    <p>{`Wir werfen einen Blick auf die Geschichte des Elements, wie es funktioniert und wie es sich auf die Barrierefreiheit
auswirkt.`}</p>
    <h2>{`Eine kurze Geschichte des Beschreibungsliste-Elements`}</h2>
    <p>{`Das `}<InlineCode mdxType="InlineCode">{`<`}{`dl`}{`>`}</InlineCode>{`-Element gibt es seit `}<a parentName="p" {...{
        "href": "https://www.w3.org/MarkUp/HTMLPlus/htmlplus_34.html"
      }}>{`HTML+`}</a>{`
im Jahr 1993. Das Dokument definierte das Element noch als „Definitionsliste“:`}</p>
    <blockquote lang="en">
    A <BoldText mdxType="BoldText">definition list</BoldText> is a list of terms and corresponding definitions. Definition lists are  
    typically formatted with the term flush-left and the definition, formatted paragraph style, indented after the term.
    </blockquote>
    <p>{`Diese enge Definition ist auch heute noch in vielen Köpfen präsent. Aber sie gilt nicht mehr. Die Definition des Elements
wurde in `}<a parentName="p" {...{
        "href": "https://html.spec.whatwg.org/multipage/grouping-content.html#the-dl-element"
      }}>{`HTML5`}</a>{` geändert, wodurch es vielseitiger wurde:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The `}<BoldText mdxType="BoldText">{`dl element`}</BoldText>{` represents an association list consisting of zero or more name-value groups
(a `}<BoldText mdxType="BoldText">{`description list`}</BoldText>{`). `}{`[...]`}{` Name-value groups may be terms and definitions, metadata topics and
values, questions and answers, or any other groups of name-value data.`}</p>
    </blockquote>
    <p>{`Das bedeutet, dass das Element zur Kennzeichnung einer beliebigen Liste von Schlüssel-Wert-Paaren verwendet werden kann.
Zum Beispiel eine Liste von Metadaten über einen Kunden: Name, Adresse, Telefonnummer usw.`}</p>
    <h2>{`Wie ihr das `}<InlineCode mdxType="InlineCode">{`<`}{`dl`}{`>`}</InlineCode>{`-Element verwendet`}</h2>
    <p>{`Die `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dl"
      }}>{`Beschreibungsliste`}</a>{`
umfasst eine Liste an Begriffen und Beschreibungen, die mit den HTML-Elementen `}<InlineCode mdxType="InlineCode">{`<`}{`dt`}{`>`}</InlineCode>{`
und `}<InlineCode mdxType="InlineCode">{`<`}{`dd`}{`>`}</InlineCode>{` definiert werden. Hier ein Beispiel einer Liste an Metadaten:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<dl>
    <dt>Account Owner</dt>
    <dd>Martina Musterfrau</dd>
    <dt>Banking Institute</dt>
    <dd>Musterbank</dd>
    <dt>IBAN</dt>
    <dd>AT12 3456 7891 2345 6789</dd>
    <dt>Telephone</dt>
    <dd>+43 1234 56 78</dd>
</dl>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Die semantischen HTML-Tags vermitteln wichtige Informationen an assistive Technologien. Screenreader können in der
Liste navigieren und die Beziehung zwischen Begriff und Beschreibung vermitteln. Aber auch Web-Entwickler:innen
profitieren von dem semantischen Markup. Stellt euch vor, das Beispiel oben würde als Abfolge
von `}<InlineCode mdxType="InlineCode">{`<`}{`div`}{`>`}</InlineCode>{`-Elementen implementiert:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<div class="metadata">
    <div class="term">Account Owner</div>
    <div class="description">Martina Musterfrau</div>
    <div class="term">Banking Institute</div>
    <div class="description">Musterbank</div>
    <div class="term">IBAN</div>
    <div class="description">AT12 3456 7891 2345 6789</div>
    <div class="term">Telephone</div>
    <div class="description">+43 1234 56 78</div>
</div>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Ich hoffe, ihr stimmt mir zu: Semantisches HTML ist eindeutig leichter zu verstehen und ihr könnt damit besser arbeiten.
Ihr könnt die semantischen Tag-Namen auch für aussagekräftige und leicht verständliche CSS-Selektoren nutzen. Lest
meinen Artikel `}<a parentName="p" {...{
        "href": "https://www.oidaisdes.org/de/3-ways-web-devs-benefit-accessibility.de/"
      }}>{`„3 Gründe, warum auch Web-Entwickler:innen von Barrierefreiheit profitieren“`}</a>{`.`}</p>
    <h2>{`Styling der Description List`}</h2>
    <p>{`Das Standard-Design von Beschreibungslisten ist eher fad. Aber keine Sorge! Ihr könnt das Element frei mit CSS gestalten.
Die HTML-Spezifikation erlaubt es, jedes Schlüssel-Wert-Paar in ein `}<InlineCode mdxType="InlineCode">{`<`}{`div`}{`>`}</InlineCode>{`-Element zu verpacken.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<dl>
    <div>
        <dt>Account Owner</dt>
        <dd>Martina Musterfrau</dd>
    </div>
    <div>
        <dt>Banking Institute</dt>
        <dd>Musterbank</dd>
    </div>
    <div>
        <dt>IBAN</dt>
        <dd>AT12 3456 7891 2345 6789</dd>
    </div>
</dl>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Diese Struktur des Inhalts erlaubt uns, ein responsives, horizontales Layout mit
`}<a parentName="p" {...{
        "href": "https://css-tricks.com/snippets/css/a-guide-to-flexbox/"
      }}>{`CSS-Flexbox`}</a>{` zu gestalten:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`dl {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

dl > div {
    flex-grow: 1;
}

dt {
    color: #6e2755;
    font-size: 0.9rem;
    font-weight: bold;
}

dd {
    font-size: 1rem;
    margin-inline-start: 0;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`So sieht die Liste am Ende aus:`}</p>
    <iframe title="Flexbox styled Description List" src="https://codepen.io/alexlehner86/embed/eYLwwaa?default-tab=result" loading="lazy">
    See the Pen <a href="https://codepen.io/alexlehner86/pen/eYLwwaa">
    Flexbox styled Description List</a> by Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <h2>{`Screenreader-Unterstützung`}</h2>
    <p>{`Das Beschreibungsliste-Element macht eure Inhalte besser zugänglich, insbesondere für Screenreader-Nutzer:innen.
Es gibt kleine Unterschiede zwischen Browsern und Screenreadern, aber insgesamt ist die Unterstützung ziemlich gut.
Adrian Roselli hat für seinen Artikel
`}<a parentName="p" {...{
        "href": "https://adrianroselli.com/2022/12/brief-note-on-description-list-support.html"
      }}><span lang="en">{`„Brief Note on Description List Support“`}</span></a>{`
ausführliche Screenreader-Tests durchgeführt.`}</p>
    <p>{`Ich will seiner Analyse nur einen Gedanken hinzufügen: Screenreader sind auch nur Software. Programme können Bugs
haben oder Features nicht vollständig implementieren (wie etwa den korrekten Umgang mit dem `}<InlineCode mdxType="InlineCode">{`<`}{`dl`}{`>`}</InlineCode>{`-Element).
Aber das sollte uns nicht davon abhalten, dieses Feature in der Web-Entwicklung einzusetzen. Vor allem dann, wenn absehbar
ist, dass die Screenreader-Hersteller die Fehler in naher Zukunft beheben werden.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      